    *, *:before, *:after {
    box-sizing: inherit;
  }
  * {margin:0;padding:0;border:0 none; }
  
/* NAV BAR */
.lineDecoration {
    border-top: 2px solid #000000;
    max-width: 120px;
    margin: 2px auto 0 auto;
}

.dropdown {
    position: relative;
    display: inline-block;
}
  
.dropdown-content {
    display: flex;
    justify-content:center;
    align-content: center;
}

.dropdown-content-align {
    display: none;
    position: absolute;
    background-color: #000000;
    z-index: 1;
}
  
.dropdown-content a {
    color: #FFFFFF;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown:hover .dropdown-content-align {
    display: block;
}

.dropdown:hover .lineDecoration {
    border-top: 2px solid #5D5D60;
}

@media (max-width:700px) {
    .dropdown {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .dropdown-content {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .dropdown-content-align {
        display: none;
        background-color: #000000;
        position: relative;
    }

    .dropdown:hover .dropdown-content-align {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        align-items: center;
    }
    
}